import React, { Component } from 'react';
import moment from 'moment';
import ExamSvg from '../svg/ExamSvg';
import ScoreSvg from '../svg/ScoreSvg';
import DateSvg from '../svg/DateSvg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCompanyPath } from '../../actions/company_path';

class Card2 extends Component {
  // Tarihi formatlamak için bir fonksiyon
  formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  }

  splitTitle = (title) => {
    if (title.length > 19) {
      return title.substring(0, 19) + "..";
    } else {
      return title;
    }
  }

  formatName = (name) => {
    const splt = name.split(' ');
    if (splt.length > 1) {
      return `${splt[0][0].toUpperCase()}. ${splt[1]}`;
    } else {
      return name; // Eğer isim tek kelime ise
    }
  }


  getLangCode = () => {
    const langId = localStorage.getItem('language'); // Dili buradan alıyoruz
    return langId === '1' ? '' : '/en';
  };
  render() {
    const { title, student_name, score, image_full_url, date, company, url = "", company_id, contentData } = this.props;

    const langCode = this.getLangCode(); // Dinamik dil kodunu al

    return (
      <div className='rounded-[12px] border-[1px] shadow-sm  flex flex-col items-center p-[16px]'>
        <div className='flex flex-col w-full items-center gap-[4px] mb-[15px]'>
          <div className='p-[2px] border-[1px] rounded-[50px]'>
            <img src={image_full_url} className='rounded-[50px] w-[70px] h-[70px]' alt='Student' />
          </div>

          <h4 className='font-[400] text-[18px] text-[#000]'>
            {this.formatName(student_name)}
          </h4>

          <span className='text-[13px] font-[400] text-[#ACACAC]'>
            {contentData?.student}
          </span>

          <Link to={`${langCode}${getCompanyPath(company_id)}`}>
            <span className='text-[var(--blue)] font-[600] text-[13px]'>
              {company}
            </span>
          </Link>
        </div>

        <div className='flex flex-col pl-[30px] gap-[8px] pt-[12px] w-full border-dashed border-t-[1px] border-[#ACACAC]'>
          <div className='flex items-center gap-[10px]'>
            <ExamSvg />
            <div className='flex items-center gap-[2px]'>
              <span className='text-[15px] font-[200] text-[#000]'>{contentData?.exam} :</span>
              <span className='text-[14px] font-[300] text-[#000]'> {title}</span>
            </div>
          </div>

          <div className='flex items-center gap-[10px]'>
            <ScoreSvg fill='#545454' width='20' height='20' />
            <div className='flex items-center gap-[2px]'>
              <span className='text-[15px] font-[200] text-[#000]'>{contentData?.score} :</span>
              <span className='text-[14px] font-[300] text-[#000]'> {score}</span>
            </div>
          </div>

          <div className='flex items-center gap-[10px]'>
            <DateSvg />
            <div className='flex items-center gap-[2px]'>
              <span className='text-[15px] font-[200] text-[#000]'>{contentData?.date} :</span>
              <span className='text-[14px] font-[300] text-[#000]'> {this.formatDate(date)}</span>
            </div>
          </div>
        </div>
        {/* 
        <a href={url} target='_blank' rel='noopener noreferrer' className='text-[#fff] bg-[var(--blue)] mt-[18px] text-[14px] font-[500] p-[4px_8px] border-[var(--blue)] rounded-[20px] border-[1px]'>
          {contentData?.btn_view_certificate}
        </a> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
})

export default connect(mapStateToProps)(Card2);