import React, { Component } from 'react';
import aze from '../../images/azerbaijan_flag.png';
import ing from '../../images/ingiltere_flag.png';

export class LanguageNav extends Component {
  state = {
    languageVisable: aze,
    languageValue: '1',
    languageCode: 'az',
  };

  componentDidMount() {
    const savedLanguage = localStorage.getItem('language') || '1';
    const savedFlag = localStorage.getItem('flag') || aze;
    const savedLangCode = localStorage.getItem('langCode') || 'az';

    //default
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', '1');
      localStorage.setItem('flag', aze);
      localStorage.setItem('langCode', 'az');
    }

    this.setState({
      languageValue: savedLanguage,
      languageVisable: savedFlag,
      languageCode: savedLangCode,
    });


    const currentPath = window.location.pathname;
    if (savedLangCode === 'en' && !currentPath.startsWith('/en')) {
      window.location.href = `/en${currentPath}`;
    } else if (savedLangCode === 'az' && currentPath.startsWith('/en')) {
      window.location.href = currentPath.replace('/en', '');
    }
  }

  handleLanguageChange = () => {
    const { languageCode } = this.state;
    const newLangCode = languageCode === 'az' ? 'en' : 'az';
    const newFlag = newLangCode === 'en' ? ing : aze;
    const newLangId = newLangCode === 'en' ? '2' : '1';
    const currentPath = window.location.pathname.replace(/\/en/, '');
    const newPath = newLangCode === 'en' ? `/en${currentPath}` : currentPath;

    this.setState({
      languageValue: newLangId,
      languageVisable: newFlag,
      languageCode: newLangCode,
    });

    //localStorage qeyd et
    localStorage.setItem('language', newLangId);
    localStorage.setItem('flag', newFlag);
    localStorage.setItem('langCode', newLangCode);

    window.location.href = newPath;
  };

  render() {
    const { languageVisable } = this.state;

    return (
      <div className='flex flex-col items-start bg-[#fff]'>
        <div onClick={this.handleLanguageChange} className='flex items-start relative gap-[2px] cursor-pointer'>
          <img className='w-[40px] h-[25px] rounded-[4px]' src={languageVisable} alt="flag" />
        </div>
      </div>
    );
  }
}

export default LanguageNav;